import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Upload, DatePicker, message, ConfigProvider } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import th_TH from 'antd/lib/locale/th_TH';

dayjs.locale('th');

const { Option } = Select;

// Define expense categories as a constant to avoid repetition
const EXPENSE_CATEGORIES = [
  { id: 1, key: 'postoffice' },
  { id: 2, key: 'transport' },
  { id: 3, key: 'gas' },
  { id: 4, key: 'laundry' },
  { id: 5, key: 'utilities' },
  { id: 6, key: 'return' },
  { id: 7, key: 'others' },
  { id: 8, key: 'facebook' },
  { id: 9, key: 'tiktok' },
  { id: 10, key: 'salary' },
  { id: 11, key: 'commission' },
  { id: 12, key: 'maintenance' },
  { id: 13, key: 'renovation' },
  { id: 14, key: 'blanket_return' },
  { id: 15, key: 'key_return' },
  { id: 16, key: 'refund' },
  { id: 17, key: 'travel_expense' },
  { id: 18, key: 'staff_meals_and_drinks' },
  { id: 19, key: 'stationery_supplies' },
  { id: 20, key: 'stationery_equipment' },
  { id: 21, key: 'teaching_materials_and_equipment' },
  { id: 22, key: 'IT_equipment' },
  { id: 23, key: 'learning_materials_and_equipment' }
];

const AddExpense = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const API_URL = process.env.REACT_APP_API_URL;

  const handleUpload = (file) => {
    setFile(file);
    setFileList([{
      uid: `file-${Date.now()}`, // Ensure unique ID for upload
      name: file.name,
      status: 'done',
      url: URL.createObjectURL(file),
    }]);
    return false;
  };

  const formatCurrency = (value) => {
    if (!value) return '';
    return new Intl.NumberFormat('th-TH', { 
      style: 'currency', 
      currency: 'THB'
    }).format(value);
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      
      // Prepare form data
      const amount = parseFloat(values.amount?.replace(/[^0-9.-]/g, '') || 0);
      
      formData.append('expense_type', values.expense_type);
      formData.append('category_id', values.category_id);
      formData.append('amount', amount);
      formData.append('description', values.description || '');
      formData.append('date', dayjs(values.date).format('YYYY-MM-DD'));
      formData.append('month_year', dayjs(values.month_year).format('YYYY-MM'));
      
      if (file) {
        formData.append('receipt', file);
      }

      const token = localStorage.getItem('token');
      
      await axios.post(`${API_URL}/expense`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      message.success(t('expense_added_successfully'));
      
      // Reset form
      form.resetFields();
      setFile(null);
      setFileList([]);
      
    } catch (error) {
      console.error('Error submitting expense:', error);
      message.error(t('add_expense_failed'));
    } finally {
      setLoading(false);
    }
  };

  // Clean up object URLs on unmount
  useEffect(() => {
    return () => {
      fileList.forEach(file => {
        if (file.url && file.url.startsWith('blob:')) {
          URL.revokeObjectURL(file.url);
        }
      });
    };
  }, [fileList]);

  return (
    <ConfigProvider locale={th_TH}>
      <Form 
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        className="expense-form"
      >
        <Form.Item
          name="month_year"
          label={t('month_year_label')}
          rules={[{ required: true, message: t('month_year_required') }]}
        >
          <DatePicker 
            picker="month" 
            format="MM/YYYY"
          />
        </Form.Item>

        <Form.Item
          name="date"
          label={t('date_expense')}
          rules={[{ required: true, message: t('date_required') }]}
        >
          <DatePicker 
            format="DD/MM/YYYY"
          />
        </Form.Item>

        <Form.Item
          name="category_id"
          label={t('category_label')}
          rules={[{ required: true, message: t('category_required') }]}
        >
          <Select placeholder={t('select_category')}>
            {EXPENSE_CATEGORIES.map(category => (
              <Option key={`category-${category.id}`} value={category.id}>
                {t(category.key)}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="expense_type"
          label={t('details')}
          rules={[{ required: true, message: t('type_required') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="amount"
          label={t('amount_label')}
          rules={[{ required: true, message: t('amount_required') }]}
        >
          <Input
            onChange={(e) => {
              const numericValue = e.target.value.replace(/[^0-9.]/g, '');
              form.setFieldsValue({ amount: numericValue });
            }}
            onBlur={(e) => {
              const numericValue = parseFloat(e.target.value);
              if (!isNaN(numericValue)) {
                const formattedValue = formatCurrency(numericValue);
                form.setFieldsValue({ amount: formattedValue });
              }
            }}
          />
        </Form.Item>

        <Form.Item 
          name="description" 
          label={t('memo')}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item label={t('upload_receipt_label')}>
          <Upload
            beforeUpload={handleUpload}
            maxCount={1}
            accept=".pdf"
            fileList={fileList}
            onRemove={() => {
              setFile(null);
              setFileList([]);
            }}
            onChange={({ fileList }) => setFileList(fileList)}
          >
            <Button icon={<UploadOutlined />}>{t('upload_button')}</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button 
            type="primary" 
            htmlType="submit" 
            loading={loading}
          >
            {t('submit_button')}
          </Button>
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
};

export default withRoleAccess(AddExpense, ['superadmin', 'admin']);