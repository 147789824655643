import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { 
  Layout, 
  Card, 
  Input, 
  Button, 
  Table, 
  Typography, 
  Modal, 
  Space, 
  Avatar, 
  Tag, 
  Pagination,
  Spin,
  Alert,
  Tooltip,
  message
} from 'antd';
import { 
  DownloadOutlined, 
  CalendarOutlined, 
  TeamOutlined, 
  ClockCircleOutlined,
  SearchOutlined
} from '@ant-design/icons';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { sarabunFont } from '../../sarabunFont';
import './Classes.css';

const { Content } = Layout;
const { Title, Text } = Typography;

// Keeping the original font loading function
const loadSarabunFont = (doc) => {
  doc.addFileToVFS('Sarabun.ttf', sarabunFont.src.split('base64,')[1]);
  doc.addFont('Sarabun.ttf', 'Sarabun', 'normal');
  doc.setFont('Sarabun');
};

const CurrentClasses = () => {
  const { t, i18n } = useTranslation();
  const [classGroups, setClassGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [popupData, setPopupData] = useState(null);
  const [registrationsCount, setRegistrationsCount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 30;
  const API_URL = process.env.REACT_APP_API_URL;

  // Keeping all the original utility functions
  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      const options = { day: '2-digit', month: 'short', year: 'numeric' };
      return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
    } catch (error) {
      console.error('Invalid date value:', dateString);
      return 'Invalid Date';
    }
  };

  const formatTime = (timeString) => {
    try {
      const [hours, minutes] = timeString.split(':');
      return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    } catch (error) {
      console.error('Invalid time value:', timeString);
      return 'Invalid Time';
    }
  };

  const formatMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')} ${t('hrs')}`;
  };

  const formatScheduleHour = (scheduleHourString) => {
    try {
      const [hours, minutes] = scheduleHourString.split(' ').map((value) => parseInt(value, 10));
      return `${hours}:${minutes.toString().padStart(2, '0')} ${t('hrs')}`;
    } catch (error) {
      console.error('Invalid schedule hour value:', scheduleHourString);
      return 'Invalid Time';
    }
  };

  const getDayOfWeek = (dateString) => {
    const date = new Date(dateString);
    const daysOfWeek = t('daysOfWeek', { returnObjects: true });
    return daysOfWeek[date.getDay()];
  };

  // API calls remain the same
  const fetchClassGroups = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/class-groups`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setClassGroups(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching class groups:', error);
      setError(t('fetchClassGroupsError'));
      setLoading(false);
    }
  }, [API_URL, t]);

  const fetchRegistrationsCount = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/registrations/registrations-count`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setRegistrationsCount(response.data);
    } catch (error) {
      console.error('Error fetching registrations count:', error);
      setError(t('fetchRegistrationsCountError'));
    }
  }, [API_URL, t]);

  useEffect(() => {
    fetchClassGroups();
    fetchRegistrationsCount();
  }, [fetchClassGroups, fetchRegistrationsCount]);

  // Schedule modal handlers
  const handleScheduleClick = (schedule) => {
    const sortedSchedule = [...schedule].sort((a, b) => new Date(b.date) - new Date(a.date));
    setPopupData(sortedSchedule);
  };

  const handleDownloadPDF = () => {
    try {
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });
      
      loadSarabunFont(doc);
      
      const colors = {
        primary: '#2B2D42',
        secondary: '#8D99AE',
        accent: '#0066CC',
        text: '#2B2D42'
      };
      
      const margin = 20;
      const pageWidth = doc.internal.pageSize.getWidth();
      const contentWidth = pageWidth - (margin * 2);
      
      // First, create a function to calculate scaled dimensions while maintaining aspect ratio
const calculateImageDimensions = (originalWidth, originalHeight, targetHeight) => {
  const aspectRatio = originalWidth / originalHeight;
  const newHeight = targetHeight;
  const newWidth = targetHeight * aspectRatio;
  return { width: newWidth, height: newHeight };
};

const headerImage = new Image();
headerImage.src = '/images/plann_logo_white.png';

headerImage.onload = () => {
  // Calculate proper dimensions maintaining aspect ratio
  const targetHeight = 10; // Desired height in mm
  const { width: logoWidth, height: logoHeight } = calculateImageDimensions(
    headerImage.width,
    headerImage.height,
    targetHeight
  );

  // Header section with adjusted spacing
  const headerMarginTop = margin;
  const headerMarginLeft = margin;
  
  // Add logo with proper dimensions
  doc.addImage(
    headerImage,
    'PNG',
    headerMarginLeft,
    headerMarginTop,
    logoWidth,
    logoHeight
  );
  
  
  // Company name
  doc.setFont('helvetica', 'bold');
  doc.setFontSize(16);
  doc.setTextColor(colors.primary);
  doc.setFont('helvetica', 'normal');
  doc.setFontSize(9);
  doc.setTextColor(colors.secondary);
  
  // Right-aligned contact information
  const pageWidth = doc.internal.pageSize.getWidth();
  const rightMargin = pageWidth - margin;
  
  doc.text([
    'Patio Pattanakarn 38, Bangkok',
    'info@plann.tech  |  092-365-5442'
  ], rightMargin, headerMarginTop + 8, { 
    align: 'right',
    lineHeightFactor: 1.5 
  });
  
  // Separator line below header
  doc.setDrawColor(colors.secondary);
  doc.setLineWidth(0.1);
  doc.line(
    margin, 
    headerMarginTop + logoHeight + 10, 
    pageWidth - margin, 
    headerMarginTop + logoHeight + 10
  );

        
        // Class Information
        const classCode = popupData[0]?.class_code || 'N/A';
        const totalHours = popupData?.[0]?.total_hours || 0;
        const subjects = [...new Set(popupData.map(slot => slot.subject_name))];
        
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(24);
        doc.setTextColor(colors.primary);
        doc.text(classCode, margin, margin + 55);
        
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.setTextColor(colors.text);
        
        const classDetails = [
          `Total Duration: ${formatMinutesToHours(totalHours)}`,
          `Subjects: ${subjects.join(' • ')}`
        ];
        
        doc.text(classDetails, margin, margin + 65);
        
        // Sort schedule data
        const sortedPopupData = popupData.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA.getTime() === dateB.getTime() 
            ? a.schedule_time.localeCompare(b.schedule_time)
            : dateA - dateB;
        });
        
        // Table with reduced row height
        doc.autoTable({
          startY: margin + 80,
          head: [[
            { content: 'SUBJECT', styles: { halign: 'left' } },
            { content: 'DAY', styles: { halign: 'center' } },
            { content: 'DATE', styles: { halign: 'center' } },
            { content: 'TIME', styles: { halign: 'center' } },
            { content: 'DURATION', styles: { halign: 'center' } }
          ]],
          body: sortedPopupData.map(slot => [
            slot.subject_name,
            getDayOfWeek(slot.date),
            formatDate(slot.date),
            `${formatTime(slot.schedule_time)} - ${formatTime(slot.end_time)}`,
            formatScheduleHour(slot.schedule_hour)
          ]),
          styles: {
            font: 'Sarabun',
            fontSize: 9,
            cellPadding: 4, // Reduced from 8 to 4
            lineColor: colors.secondary,
            lineWidth: 0.1,
            minCellHeight: 6 // Added to control minimum cell height
          },
          headStyles: {
            fillColor: colors.primary,
            textColor: '#FFFFFF',
            fontSize: 10,
            fontStyle: 'bold',
            cellPadding: 5 // Slightly larger padding for header
          },
          columnStyles: {
            0: { cellWidth: 'auto' },
            1: { cellWidth: 30, halign: 'center' },
            2: { cellWidth: 35, halign: 'center' },
            3: { cellWidth: 45, halign: 'center' },
            4: { cellWidth: 30, halign: 'center' }
          },
          alternateRowStyles: {
            fillColor: '#F8F9FA'
          },
          margin: { top: 80 },
          rowPageBreak: 'avoid', // Tries to avoid breaking rows across pages
          bodyStyles: {
            lineHeight: 1.2 // Reduced line height for more compact rows
          }
        });
        
        // Footer with page numbers
        const totalPages = doc.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          
          doc.setDrawColor(colors.secondary);
          doc.setLineWidth(0.1);
          doc.line(margin, doc.internal.pageSize.height - 20, pageWidth - margin, doc.internal.pageSize.height - 20);
          
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(8);
          doc.setTextColor(colors.secondary);
          doc.text(
            'plann.tech',
            margin,
            doc.internal.pageSize.height - 10
          );
          doc.text(
            `Page ${i} of ${totalPages}`,
            pageWidth - margin,
            doc.internal.pageSize.height - 10,
            { align: 'right' }
          );
        }
        
        // Save PDF
        doc.save(`${classCode}.pdf`);
        message.success('Schedule downloaded successfully');
      };
      
      headerImage.onerror = () => {
        message.error('Error loading logo. Generating PDF without logo.');
      };
    } catch (error) {
      console.error('Error generating PDF:', error);
      message.error('Error generating PDF. Please try again.');
    }
  };

  // Data processing
  const currentDate = new Date();
  const sortedClassGroups = classGroups
    .filter(cls => cls.isprivate === false && new Date(cls.end_date) >= currentDate)
    .sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

  const filteredClassGroups = sortedClassGroups.filter(cls =>
    cls.class_code.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getRegisteredStudents = (groupId) => {
    const group = registrationsCount.find(rc => rc.groupid === groupId);
    return group ? group.registered_students : 0;
  };

  // Table columns configuration for the schedule modal
  const scheduleColumns = [
    {
      title: t('subjectName'),
      dataIndex: 'subject_name',
      key: 'subject_name',
    },
    {
      title: t('day'),
      dataIndex: 'date',
      key: 'day',
      render: (date) => getDayOfWeek(date),
    },
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      render: (date) => formatDate(date),
    },
    {
      title: t('time'),
      key: 'time',
      render: (_, record) => `${formatTime(record.schedule_time)} - ${formatTime(record.end_time)}`,
    },
    {
      title: t('hours'),
      dataIndex: 'schedule_hour',
      key: 'hours',
      render: (hours) => formatScheduleHour(hours),
    },
  ];


  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return <Alert message={error} type="error" />;
  }

  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Header />
        <Content style={{ marginTop: '50px', padding: '24px', minHeight: '280px' }}>
          <div style={{ maxWidth: 1200, margin: '0 auto' }}>
            <Title level={2}>{t('ourCourses')}</Title>
            
            <Space direction="vertical" size="large" style={{ width: '100%', marginBottom: 24 }}>
              <Input
                prefix={<SearchOutlined />}
                placeholder={t('searchByClassCode')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ maxWidth: 300 }}
              />
              
              <div style={{ display: 'grid', gap: '24px', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))' }}>
                {filteredClassGroups.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((cls) => (
                  <Card
                    key={cls.groupid}
                    hoverable
                    actions={[
                      <Tooltip title={t('viewSchedule')}>
                        <Button 
                          type="link" 
                          icon={<CalendarOutlined />}
                          onClick={() => handleScheduleClick(cls.classes)}
                        >
                          {t('viewSchedule')}
                        </Button>
                      </Tooltip>
                    ]}
                  >
                    <Space direction="vertical" size="small" style={{ width: '100%' }}>
                      <Space align="center">
                        <Avatar 
                          style={{ 
                            backgroundColor: ['#f56a00', '#7265e6', '#ffbf00'][cls.groupid % 3],
                            verticalAlign: 'middle' 
                          }}
                        >
                          {cls.class_code.charAt(0)}
                        </Avatar>
                        <Title level={4} style={{ margin: 0 }}>{cls.class_code}</Title>
                      </Space>

                      <Space direction="vertical" size={0}>
                        <Text type="secondary">
                          <CalendarOutlined /> {formatDate(cls.start_date)} - {formatDate(cls.end_date)}
                        </Text>
                        <Text>
                          <ClockCircleOutlined /> {t('totalHours')}: {formatMinutesToHours(cls.total_hours)}
                        </Text>
                        <Text>
                          <TeamOutlined /> {t('maxRegister')}: {cls.register_capacity}
                        </Text>
                        <Text>
                          {t('totalRegister')}: {getRegisteredStudents(cls.groupid)}
                        </Text>
                        <Tag color={cls.isprivate ? 'purple' : 'blue'}>
                          {cls.isprivate ? t('private') : t('group')}
                        </Tag>
                      </Space>
                    </Space>
                  </Card>
                ))}
              </div>

              <Pagination
                current={currentPage}
                total={filteredClassGroups.length}
                pageSize={itemsPerPage}
                onChange={(page) => setCurrentPage(page)}
                showSizeChanger={false}
                style={{ textAlign: 'center' }}
              />
            </Space>

            <Modal
              title={popupData?.[0]?.class_code || t('scheduleDetails')}
              open={!!popupData}
              onCancel={() => setPopupData(null)}
              width={900}
              footer={[
                <Button 
                  key="download" 
                  type="primary" 
                  icon={<DownloadOutlined />}
                  onClick={handleDownloadPDF}
                >
                  {t('downloadPDF')}
                </Button>,
                <Button key="close" onClick={() => setPopupData(null)}>
                  {t('close')}
                </Button>
              ]}
            >
              <Table
                columns={scheduleColumns}
                dataSource={popupData}
                rowKey={(record, index) => index}
                pagination={false}
                scroll={{ x: true }}
              />
            </Modal>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default withRoleAccess(CurrentClasses, ['admin', 'superadmin', 'teacher', 'student']);