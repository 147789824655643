import React, { useEffect, useState } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import {
  Layout,
  Tabs,
  Row,
  Col,
  Spin,
  Alert,
  Typography,
  Card,
  Space,
  Button,
  Modal,
  Descriptions,
  Image
} from 'antd';
import {
  BookOutlined,
  ExperimentOutlined,
  CalculatorOutlined,
  ExperimentFilled,
  ReadOutlined
} from '@ant-design/icons';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';

const { Content } = Layout;
const { Title, Text } = Typography;

// SubjectCard Component
const SubjectCard = ({ subject, onDetailsClick }) => {
  const { t } = useTranslation();
  const iconMapping = {
    'English': <ReadOutlined />,
    'Physics': <ExperimentOutlined />,
    'Mathematics': <CalculatorOutlined />,
    'Chemistry': <ExperimentFilled />
  };
  const SubjectIcon = iconMapping[subject.subjectgroup] || <BookOutlined />;

  return (
    <Card
      hoverable
      cover={
        <div style={{
          height: 200,
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#f5f5f5'
        }}>
          <img
            alt={subject.title}
            src={subject.image_url || 'default-image.jpg'}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
        </div>
      }
      actions={[
        <Button type="primary" onClick={() => onDetailsClick(subject)}>
          {t('viewDetails')}
        </Button>
      ]}
    >
      <Space direction="vertical" size={16} style={{ width: '100%' }}>
        <Space align="center">
          {SubjectIcon}
          <Title level={4} style={{ margin: 0 }}>{subject.title}</Title>
        </Space>

        <Space direction="vertical" size={4}>
          <Text>
            <Text strong>{t('code')}: </Text>
            {subject.code}
          </Text>
          <Text>
            <Text strong>{t('year')}: </Text>
            {subject.year}
          </Text>
          <Text>
            <Text strong>{t('level')}: </Text>
            {subject.level}
          </Text>
        </Space>
      </Space>
    </Card>
  );
};

// SubjectDetailsModal Component
const SubjectDetailsModal = ({ subject, open, onClose }) => {
  const { t } = useTranslation();

  if (!subject) return null;

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      width={800}
      title={
        <Title level={3} style={{ margin: 0 }}>
          {subject.title}
        </Title>
      }
    >
      <Space direction="vertical" size={24} style={{ width: '100%' }}>
        <Image
          src={subject.image_url || 'default-image.jpg'}
          alt={subject.title}
          style={{ width: '100%', maxHeight: '300px', objectFit: 'cover' }}
          fallback="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMAAAADA..."
        />

        <Descriptions
          bordered
          column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
        >
          <Descriptions.Item label={t('id')}>
            {subject.subjectid}
          </Descriptions.Item>
          <Descriptions.Item label={t('group')}>
            {subject.subjectgroup}
          </Descriptions.Item>
          <Descriptions.Item label={t('code')}>
            {subject.code}
          </Descriptions.Item>
          <Descriptions.Item label={t('year')}>
            {subject.year}
          </Descriptions.Item>
          <Descriptions.Item label={t('level')}>
            {subject.level}
          </Descriptions.Item>
          <Descriptions.Item label={t('specification')} span={2}>
            {subject.specification}
          </Descriptions.Item>
          <Descriptions.Item label={t('description')} span={2}>
            {subject.description}
          </Descriptions.Item>
        </Descriptions>
      </Space>
    </Modal>
  );
};

// Main Subject Component
function Subject() {
  const { t } = useTranslation();
  const [subjects, setSubjects] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const handleViewDetailsClick = (subject) => {
    setSelectedSubject(subject);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedSubject(null);
  };

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await axios.get(`${API_URL}/subjects`);
        const groupedSubjects = groupBySubjectGroup(response.data);
        setSubjects(groupedSubjects);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching subjects:', error);
        setError(t('errorFetchingSubjects'));
        setLoading(false);
      }
    };

    fetchSubjects();
  }, [API_URL, t]);

  const groupBySubjectGroup = (subjectsArray) => {
    return subjectsArray.reduce((groupedSubjects, subject) => {
      const group = subject.subjectgroup;
      if (!groupedSubjects[group]) {
        groupedSubjects[group] = [];
      }
      groupedSubjects[group].push(subject);
      return groupedSubjects;
    }, {});
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ padding: 24 }}>
        <Alert message={error} type="error" showIcon />
      </div>
    );
  }

  const items = Object.entries(subjects).map(([group, groupSubjects]) => ({
    key: group,
    label: group,
    children: (
      <Row gutter={[24, 24]} style={{ padding: '24px 0' }}>
        {groupSubjects.map(subject => (
          <Col xs={24} sm={12} md={8} lg={6} key={subject.subjectid}>
            <SubjectCard
              subject={subject}
              onDetailsClick={handleViewDetailsClick}
            />
          </Col>
        ))}
      </Row>
    ),
  }));

  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Header />
        <Content style={{  marginTop: '50px', marginBottom: '70px', padding: '24px', minHeight: '280px', background: '#f5f5f5' }}>
          <div style={{ maxWidth: 1290, margin: '0 auto' }}>
            
            <Tabs
              items={items}
              type="card"
              size="large"
              style={{ 
                background: 'white',
                padding: '24px',
                borderRadius: '8px',
                boxShadow: '0 1px 2px rgba(0,0,0,0.1)'
              }}
            />

            <SubjectDetailsModal
              open={showModal}
              subject={selectedSubject}
              onClose={handleCloseModal}
            />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default withRoleAccess(Subject, ['admin', 'superadmin', 'teacher', 'student']);