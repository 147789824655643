import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

const ClassPackagePricing = ({ packages, onPackagesChange, t }) => {
  const validityOptions = [
    { value: 1, label: t('1 month') },
    { value: 3, label: t('3 months') },
    { value: 6, label: t('6 months') },
    { value: 12, label: t('1 year') }
  ];

  const handleAddPackage = () => {
    const newPackage = {
      number_of_classes: '',
      price_per_package: '',
      validity_months: 1
    };
    onPackagesChange([...packages, newPackage]);
  };

  const handlePackageChange = (index, field, value) => {
    const updatedPackages = packages.map((pkg, i) => {
      if (i === index) {
        return { ...pkg, [field]: value };
      }
      return pkg;
    });
    onPackagesChange(updatedPackages);
  };

  const handleRemovePackage = (index) => {
    const updatedPackages = packages.filter((_, i) => i !== index);
    onPackagesChange(updatedPackages);
  };

  return (
    <div className="package-pricing-container">
      <div className="package-pricing-header">
        <h3>{t('Package Prices')}</h3>
        <button
          type="button"
          onClick={handleAddPackage}
          className="add-package-btn"
        >
          <FontAwesomeIcon icon={faPlus} /> {t('Add Package')}
        </button>
      </div>

      {packages.map((pkg, index) => (
        <div key={index} className="package-row">
          <div className="form-group">
            <label>{t('Number of Classes')}</label>
            <input
              type="number"
              min="1"
              value={pkg.number_of_classes}
              onChange={(e) => handlePackageChange(index, 'number_of_classes', e.target.value)}
              className="package-input"
              placeholder={t('Enter Number of Classes')}
            />
          </div>

          <div className="form-group">
            <label>{t('Price')}</label>
            <input
              type="number"
              min="0"
              value={pkg.price_per_package}
              onChange={(e) => handlePackageChange(index, 'price_per_package', e.target.value)}
              className="package-input"
              placeholder={t('Enter Price')}
            />
          </div>

          <div className="form-group">
            <label>{t('Expiry Period')}</label>
            <select
              value={pkg.validity_months}
              onChange={(e) => handlePackageChange(index, 'validity_months', e.target.value)}
              className="package-select"
            >
              {validityOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <button
            type="button"
            onClick={() => handleRemovePackage(index)}
            className="remove-package-btn"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ))}
    </div>
  );
};

export default ClassPackagePricing;