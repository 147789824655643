import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { Button, Input, Table, Modal, message, Spin, Tag } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import ScheduleModal from './ScheduleModal'; // Import the ScheduleModal
import './Classes.css';

const { confirm } = Modal;

const FilterTaskTable = () => {
  const { t, i18n } = useTranslation();
  const [classGroups, setClassGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [popupData, setPopupData] = useState(null);
  const [editData, setEditData] = useState(null);
  const [registrationsCount, setRegistrationsCount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 150;
  const API_URL = process.env.REACT_APP_API_URL;

  // Function to format date to 'dd-Month(long)-yyyy'
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date);
  };

  const formatMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')} ${t('hrs')}`;
  };

  const processClassGroups = (data) => {
    const groupedData = data.reduce((acc, item) => {
      // Check if the groupid already exists in the accumulator
      const existing = acc.find((entry) => entry.groupid === item.groupid);
  
      if (existing) {
        // If groupid exists, join the prices and classgroup_ids
        existing.price = existing.price
          ? `${existing.price}, ${item.price || ''}`.trim()
          : item.price;
        existing.classgroup_ids = existing.classgroup_ids
          ? `${existing.classgroup_ids}, ${item.classgroup_id}`
          : item.classgroup_id;
      } else {
        // If groupid does not exist, add it to the accumulator
        acc.push({
          ...item,
          classgroup_ids: item.classgroup_id,
        });
      }
  
      return acc;
    }, []);
  
    return groupedData;
  };
  

  const fetchClassGroups = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      console.log('Token:', token);
  
      const response = await axios.get(`${API_URL}/class-groups`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      console.log('Raw API Data:', response.data);
  
      // Process the data to handle duplicate groupid and combine prices
      const processedData = processClassGroups(response.data);
      console.log('Processed Data:', processedData);
  
      setClassGroups(processedData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching class groups:', error);
      setError(t('fetchClassGroupsError'));
      setLoading(false);
    }
  }, [API_URL, t]);
  
  

  const fetchRegistrationsCount = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/registrations/registrations-count`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRegistrationsCount(response.data);
    } catch (error) {
      console.error('Error fetching registrations count:', error);
      setError(t('fetchRegistrationsCountError'));
    }
  }, [API_URL, t]);

  useEffect(() => {
    fetchClassGroups();
    fetchRegistrationsCount();
  }, [fetchClassGroups, fetchRegistrationsCount]);

  const confirmDeleteClassGroup = (groupID) => {
    confirm({
      title: t('confirmDeleteClassGroup'),
      icon: <ExclamationCircleOutlined />,
      content: t('deleteClassGroupWarning'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk() {
        handleDeleteClassGroup(groupID);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleDeleteClassGroup = async (groupID) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${API_URL}/class-groups/${groupID}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setClassGroups((prevClasses) => prevClasses.filter((cls) => cls.groupid !== groupID));
      message.success(t('classGroupDeleted'));
    } catch (error) {
      console.error('Error deleting class group:', error);
      message.error(t('deleteClassGroupError'));
    }
  };


  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSaveEdit = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!editData.groupID) {
        console.error('groupID is undefined');
        return;
      }
      await axios.put(`${API_URL}/class-groups/${editData.groupID}`, editData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setEditData(null);
      fetchClassGroups(); // Refresh the class list
      message.success(t('classGroupUpdated'));
    } catch (error) {
      console.error('Error updating class group:', error);
      message.error(t('updateClassGroupError'));
    }
  };

  const handleEditClick = (classGroup) => {
    setEditData({
      groupID: classGroup.groupid,
      class_code: classGroup.class_code,
      book_details_materials: classGroup.book_details_materials,
      isprivate: classGroup.isprivate,
      register_capacity: classGroup.register_capacity,
      price: classGroup.price,
    });
  };

  // Function to get the number of registered students for a class group
  const getRegisteredStudents = (groupId) => {
    const group = registrationsCount.find((rc) => rc.groupid === groupId);
    return group ? group.registered_students : 0;
  };

  const formatNumber = (number) => {
    if (!number) return "0.00";
    return parseFloat(number).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  const closeEditModal = () => {
    setEditData(null);
  };

  const columns = [
    {
      title: t('classCode'),
      dataIndex: 'class_code',
      key: 'class_code',
      render: (text, record) => (
        <Link to={`/classdetails/${record.groupid}`} style={{ fontWeight: 'bold' }}>
          {text}
        </Link>
      ),
    },
    {
      title: t('schedule'),
      key: 'schedule',
      render: (_, record) => (
        <Button onClick={() => setPopupData(record.classes)}>{t('schedule')}</Button>
      ),
    },
    { title: t('startDate'), dataIndex: 'start_date', key: 'start_date', render: formatDate },
    { title: t('endDate'), dataIndex: 'end_date', key: 'end_date', render: formatDate },
    { title: t('totalHours'), dataIndex: 'total_hours', key: 'total_hours', render: formatMinutesToHours },
    { title: t('bookDetails'), dataIndex: 'book_details_materials', key: 'book_details_materials' },
    {
      title: t('private'),
      dataIndex: 'isprivate',
      key: 'isprivate',
      render: (isPrivate) => (
        <Tag color={isPrivate ? 'blue' : 'green'}>
          {isPrivate ? t('private') : t('group')}
        </Tag>
      ),
    },
    { title: t('capacity'), dataIndex: 'register_capacity', key: 'register_capacity' },
    {
      title: t('totalRegis'),
      key: 'totalRegis',
      render: (_, record) => getRegisteredStudents(record.groupid),
    },
    {
      title: t('price'),
      key: 'price',
      render: (_, record) => {
        const hasBasePrice = record.price && record.price !== "0.00";
        const hasPackages = record.package_pricing && record.package_pricing.length > 0;
    
        return (
          <div>
            {/* Show base price if it exists and is not zero */}
            {hasBasePrice && (
              <div style={{ marginBottom: hasPackages ? '12px' : '0' }}>
                <span style={{ 
                  color: '#1890ff', // Blue color for base price
                  fontWeight: 500,
                  fontSize: '15px'
                }}>
                  {formatNumber(record.price)} {t('THB')}
                </span>
              </div>
            )}
            
            {/* Divider if both prices exist */}
            {hasBasePrice && hasPackages && (
              <div style={{
                margin: '8px 0',
                borderBottom: '1px dashed #d9d9d9',
                width: '100%'
              }} />
            )}
            
            {/* Show package pricing if available */}
            {hasPackages && (
              <ul style={{ 
                padding: 0, 
                margin: 0, 
                listStyleType: 'none',
                color: '#52c41a' // Green color for package prices
              }}>
                {record.package_pricing.map((pkg, index) => (
                  <li 
                    key={`${record.groupid}-${index}`} 
                    style={{ 
                      marginBottom: '4px',
                      fontSize: '14px'
                    }}
                  >
                    <strong style={{ color: '#262626' }}>{pkg.number_of_classes} {t('classes')}:</strong>{' '}
                    <span style={{ fontWeight: 500 }}>{formatNumber(pkg.price_per_package)} {t('THB')}</span>{' '}
                    <span style={{ color: '#8c8c8c' }}>({pkg.validity_months} {t('months')})</span>
                  </li>
                ))}
              </ul>
            )}
            
            {/* Show message if no price information is available */}
            {(!hasBasePrice && !hasPackages) && (
              <span style={{ color: '#bfbfbf', fontStyle: 'italic' }}>
                {t('noPriceAvailable')}
              </span>
            )}
          </div>
        );
      },
    },    
    {
      title: t('actions'),
      key: 'actions',
      render: (_, record) => (
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button icon={<EditOutlined />} onClick={() => handleEditClick(record)} />
          <Button icon={<DeleteOutlined />} danger onClick={() => confirmDeleteClassGroup(record.groupid)} />
        </div>
      ),
    },
  ];
  

  return (
    <div>
      <Sidebar />
      <Header />
      <div className="manage-course-container">
        <h1>{t('manageCourses')}</h1>
        <Input
          placeholder={t('searchByClassCode')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginBottom: '16px', width: '300px' }}
        />
        <Spin spinning={loading}>
          <Table
            columns={columns}
            dataSource={classGroups}
            rowKey="groupid"
            pagination={{
              current: currentPage,
              pageSize: itemsPerPage,
              total: classGroups.length,
              onChange: (page) => setCurrentPage(page),
            }}
            scroll={{ x: true }}
          />
        </Spin>

        {/* Schedule Modal */}
        <ScheduleModal isVisible={!!popupData} onClose={() => setPopupData(null)} popupData={popupData} />

        {/* Edit Modal */}
        {editData && (
          <Modal
            visible={!!editData}
            onCancel={closeEditModal}
            onOk={handleSaveEdit}
            title={t('editClassGroup')}
            okText={t('save')}
            cancelText={t('cancel')}
          >
            <div>
              <label>{t('classCode')}:</label>
              <Input type="text" name="class_code" value={editData.class_code} onChange={handleEditChange} />
            </div>
            <div>
              <label>{t('Lesson Plans')}:</label>
              <Input
                type="text"
                name="book_details_materials"
                value={editData.book_details_materials}
                onChange={handleEditChange}
              />
            </div>
            <div>
              <label>{t('private')}:</label>
              <select
                name="isprivate"
                value={editData.isprivate}
                onChange={handleEditChange}
                style={{ width: '100%', marginBottom: '16px' }}
              >
                <option value={true}>{t('private')}</option>
                <option value={false}>{t('group')}</option>
              </select>
            </div>
            <div>
              <label>{t('registerCapacity')}:</label>
              <Input
                type="number"
                name="register_capacity"
                value={editData.register_capacity}
                onChange={handleEditChange}
              />
            </div>
            <div>
              <label>{t('price')}:</label>
              <Input type="number" name="price" value={editData.price} onChange={handleEditChange} />
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default withRoleAccess(FilterTaskTable, ['admin', 'superadmin']);
