import React, { useState, useEffect, useCallback } from 'react';
import withRoleAccess from '../../hoc/withRoleAccess';
import { Card, Space, Table, Button, notification, Row, Col, Statistic } from 'antd';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DownloadOutlined, ArrowUpOutlined, ArrowDownOutlined, DollarOutlined, LineChartOutlined, CalculatorOutlined } from '@ant-design/icons';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const NetProfitDashboard = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [netProfitData, setNetProfitData] = useState([]);
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;


  const fetchData = useCallback(async () => {
    setLoading(true);
    
    // Adjust the end date to include the full day
    const adjustedEndDate = moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    
    try {
      const token = localStorage.getItem('token');
      const [incomeResponse, expenseResponse] = await Promise.all([
        axios.get(`${API_URL}/payment/income-reports`, {
          params: {
            startDate,
            endDate: adjustedEndDate,
          },
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${API_URL}/expense`, {
          params: {
            startDate,
            endDate: adjustedEndDate,
          },
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);
  
      const processedData = processNetProfitData(incomeResponse.data, expenseResponse.data);
      setNetProfitData(processedData);
  
    } catch (error) {
      console.error('Error fetching data:', error);
      notification.error({
        message: t('error_fetching_data'),
        description: error.response?.data?.message || error.message,
      });
    } finally {
      setLoading(false);
    }
  }, [API_URL, startDate, endDate]);
  
useEffect(() => {
    fetchData();
  }, [fetchData]); 
  
const calculateTotals = () => {
    return netProfitData.reduce((acc, item) => ({
      income: acc.income + item.income,
      expense: acc.expense + item.expense,
      netProfit: acc.netProfit + item.netProfit
    }), { income: 0, expense: 0, netProfit: 0 });
  };

  const totals = calculateTotals();

  const processNetProfitData = (incomeData, expenseData) => {
    const dailyData = {};
    
    // Adjust the end date to include the full day
    const adjustedEndDate = moment(endDate).endOf('day');
  
    // Process income data
    if (Array.isArray(incomeData)) {
      incomeData.forEach(income => {
        const date = moment(income.created_at).format('YYYY-MM-DD');
        if (moment(date).isBetween(startDate, adjustedEndDate, 'day', '[]')) {
          if (!dailyData[date]) {
            dailyData[date] = {
              date,
              income: 0,
              expense: 0,
            };
          }
          dailyData[date].income += Number(income.payment_amount || 0);
        }
      });
    }
  
    // Process expense data
    if (Array.isArray(expenseData)) {
      expenseData.forEach(expense => {
        const date = moment(expense.date).format('YYYY-MM-DD');
        if (moment(date).isBetween(startDate, adjustedEndDate, 'day', '[]')) {
          if (!dailyData[date]) {
            dailyData[date] = {
              date,
              income: 0,
              expense: 0,
            };
          }
          dailyData[date].expense += Number(expense.amount || 0);
        }
      });
    }
  
    // Convert to array and calculate net profit
    const result = Object.values(dailyData)
      .map(day => ({
        ...day,
        netProfit: day.income - day.expense,
        key: day.date,
      }))
      .sort((a, b) => moment(b.date).diff(moment(a.date)));
  
    return result;
  };
  

const columns = [
    {
        title: 'วันที่',
        dataIndex: 'date',
        key: 'date',
        render: (date) => moment(date).format('DD MMMM YYYY')
    },
    {
        title: 'รายรับ',
        dataIndex: 'income',
        key: 'income',
        align: 'right',
        render: (value) => `฿${Number(value).toLocaleString(undefined, { minimumFractionDigits: 2 })}`
    },
    {
        title: 'ค่าใช้จ่าย',
        dataIndex: 'expense',
        key: 'expense',
        align: 'right',
        render: (value) => `฿${Number(value).toLocaleString(undefined, { minimumFractionDigits: 2 })}`
    },
    {
        title: 'กำไร',
        dataIndex: 'netProfit',
        key: 'netProfit',
        align: 'right',
        render: (value) => (
            <div className="total-cell" 
                style={{ 
                    color: value >= 0 ? '#52c41a' : '#ff4d4f',
                    backgroundColor: value >= 0 ? '#f6ffed' : '#fff2f0',
                    border: `1px solid ${value >= 0 ? '#b7eb8f' : '#ffccc7'}`,
                    padding: '4px 8px',
                    borderRadius: '6px',
                    display: 'inline-block'
                }}>
                ฿{Math.abs(value).toLocaleString(undefined, { minimumFractionDigits: 2 })}
            </div>
        )
    }
];
const downloadPDF = async () => {
    const filename = `${startDate}_to_${endDate}_NetProfit.pdf`;
    const pdf = new jsPDF('p', 'pt', 'a4');
    const margin = 24; // 24px margin
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
  
    try {
      const element = document.querySelector('.daily-reports-container');
      if (!element) {
        notification.error({ message: t('error_generating_pdf'), description: t('element_not_found') });
        return;
      }
  
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        backgroundColor: '#ffffff',
      });
  
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = pageWidth - margin * 2;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
      let yPosition = margin;
  
      pdf.addImage(imgData, 'PNG', margin, yPosition, imgWidth, imgHeight);
  
      // Add additional pages if the content exceeds one page
      while (yPosition + imgHeight > pageHeight) {
        pdf.addPage();
        yPosition -= pageHeight - margin * 2;
        pdf.addImage(imgData, 'PNG', margin, yPosition, imgWidth, imgHeight);
      }
  
      pdf.save(filename);
      notification.success({ message: t('success_pdf_downloaded') });
    } catch (error) {
      console.error('Error generating PDF:', error);
      notification.error({ message: t('error_generating_pdf'), description: error.message });
    }
  };
  
  const exportToCSV = () => {
    const headers = ['Date', 'Income', 'Expense', 'Net Profit'];
    const rows = netProfitData.map((row) => [
      moment(row.date).format('YYYY-MM-DD'),
      row.income.toFixed(2),
      row.expense.toFixed(2),
      row.netProfit.toFixed(2),
    ]);
  
    const csvContent = [headers, ...rows].map((row) => row.join(',')).join('\n');
  
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${startDate}_to_${endDate}_NetProfit.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  return (
    <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', padding: '16px' }}>
    <Space direction="vertical" size="large" style={{ width: '100%', flex: 1 }}>
      {/* Filters */}
      <Card>
        <Row justify="space-between" align="middle">
          <Col>
            <Space size="middle">
              <Space>
                <span>{t('From')}:</span>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText={t('Select start date')}
                  format="DD-MM-YYYY"
                  className="ant-datepicker"
                />
              </Space>
              <Space>
                <span>{t('To')}:</span>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  placeholderText={t('Select end date')}
                  format="DD-MM-YYYY"
                  className="ant-datepicker"
                />
              </Space>
              <Button icon={<DownloadOutlined />} onClick={exportToCSV}>
                {t('Export to CSV')}
              </Button>
              <Button icon={<DownloadOutlined />} onClick={downloadPDF}>
                {t('Export to PDF')}
              </Button>
            </Space>
          </Col>
        </Row>
      </Card>

      {/* Statistics Cards */}
      <Row gutter={16} style={{ width: '100%' }}>
        <Col span={8}>
          <Card bordered={false} className="statistic-card" style={{ width: '100%' }}>
            <Statistic
              title={
                <div className="statistic-title">
                  <DollarOutlined style={{ fontSize: '24px', color: '#52c41a' }} />
                  <span>{t('Total Income')}</span>
                </div>
              }
              value={totals.income}
              precision={2}
              prefix="฿"
              valueStyle={{ color: '#52c41a' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false} className="statistic-card" style={{ width: '100%' }}>
            <Statistic
              title={
                <div className="statistic-title">
                  <CalculatorOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />
                  <span>{t('Total Expense')}</span>
                </div>
              }
              value={totals.expense}
              precision={2}
              prefix="฿"
              valueStyle={{ color: '#ff4d4f' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false} className="statistic-card" style={{ width: '100%' }}>
            <Statistic
              title={
                <div className="statistic-title">
                  <LineChartOutlined
                    style={{
                      fontSize: '24px',
                      color: totals.netProfit >= 0 ? '#52c41a' : '#ff4d4f',
                    }}
                  />
                  <span>{t('Net Profit')}</span>
                </div>
              }
              value={totals.netProfit}
              precision={2}
              prefix="฿"
              valueStyle={{ color: totals.netProfit >= 0 ? '#52c41a' : '#ff4d4f' }}
              suffix={totals.netProfit >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
            />
          </Card>
        </Col>
      </Row>

      {/* Chart */}
      <Card title={t('Net Profit')} style={{ width: '100%' }}>
        <div style={{ height: 400, width: '100%' }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={netProfitData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tickFormatter={(date) => moment(date).format('DD-MMM-YY')}
              />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="income"
                stroke="#52c41a"
                name={t('Income')}
              />
              <Line
                type="monotone"
                dataKey="expense"
                stroke="#ff4d4f"
                name={t('Expense')}
              />
              <Line
                type="monotone"
                dataKey="netProfit"
                stroke="#1890ff"
                name={t('Net Profit')}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Card>

      {/* Data Table */}
      <Card style={{ width: '100%' }}>
        <Table
          dataSource={netProfitData}
          columns={columns}
          pagination={false}
          rowKey="date"
          summary={(pageData) => {
            const totals = pageData.reduce(
              (acc, current) => ({
                income: acc.income + current.income,
                expense: acc.expense + current.expense,
                netProfit: acc.netProfit + current.netProfit,
              }),
              { income: 0, expense: 0, netProfit: 0 }
            );

            return (
              <Table.Summary.Row>
                <Table.Summary.Cell>{t('Total')}</Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <span style={{ color: '#52c41a' }}>
                    ฿{totals.income.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                  </span>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <span style={{ color: '#ff4d4f' }}>
                    ฿{totals.expense.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                  </span>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <span
                    style={{
                      color: totals.netProfit >= 0 ? '#52c41a' : '#ff4d4f',
                    }}
                  >
                    ฿{totals.netProfit.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                  </span>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </Card>
    </Space>
  </div>
  );
};


export default withRoleAccess(NetProfitDashboard, ['superadmin']);