import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCalendar, 
  faClock, 
  faLock, 
  faSave,
  faImage,
  faVideo,
  faTimes,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
import './Classes.css';

const MAX_IMAGES = 5;
const ACCEPTED_IMAGE_TYPES = 'image/jpeg,image/png,image/gif';

const ImagePopup = ({ image, onClose }) => (
    <div className="image-popup-backdrop" onClick={onClose}>
      <div className="image-popup-content" onClick={e => e.stopPropagation()}>
        <img src={image} alt="Full size" />
        <button className="popup-close-button" onClick={onClose}>×</button>
      </div>
    </div>
  );

const AttendanceModal = ({ 
    selectedClass,
    onClose,
    registeredStudents,
    savedAttendances,
    attendance,
    handleAttendanceChange,
    handleSaveAttendance,
    savingInProgress,
    downloadAttendanceCSV,
    formatTime,
    calculateClassEndTime,
    studentMedia,
    setStudentMedia
  }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const { t } = useTranslation();
    const API_URL = process.env.REACT_APP_API_URL;

  if (!selectedClass) return null;


  const handleImageUpload = async (studentId, files) => {
    const currentImages = studentMedia[studentId]?.images || [];
    
    if (currentImages.length + files.length > MAX_IMAGES) {
      alert(`Maximum ${MAX_IMAGES} images allowed`);
      return;
    }

    const newImages = Array.from(files).map(file => ({
      file,
      preview: URL.createObjectURL(file)
    }));

    setStudentMedia(prev => ({
      ...prev,
      [studentId]: {
        ...prev[studentId],
        images: [...(prev[studentId]?.images || []), ...newImages]
      }
    }));

  };
  const getImageUrl = (path) => {
    if (!path) {
      console.log('No path provided to getImageUrl');
      return null;
    }
  
    // Remove '/api' from API_URL for image paths specifically
    const baseUrl = API_URL.endsWith('/api') ? API_URL.slice(0, -4) : API_URL;
    const imagePath = path.startsWith('/uploads') ? path : `/uploads/${path}`;
  
    // Ensure there is only one slash between baseUrl and imagePath
    const fullUrl = `${baseUrl.replace(/\/+$/, '')}/${imagePath.replace(/^\/+/, '')}`;
    
    console.log('Generated URL:', {
      originalPath: path,
      imagePath,
      API_URL,
      baseUrl,
      fullUrl
    });
    
    return fullUrl;
  };
  

  const getFullImageUrl = (imagePath) => {
    if (!imagePath) return null;
  
    // Construct the base URL by removing '/api' from API_URL if present
    const baseUrl = API_URL.endsWith('/api') ? API_URL.slice(0, -4) : API_URL;
  
    // Remove any leading slashes from imagePath to avoid double slashes in the URL
    const formattedImagePath = imagePath.startsWith('/') ? imagePath.slice(1) : imagePath;
  
    // Build the full URL
    const fullUrl = `${baseUrl}/${formattedImagePath}`;

  
    return fullUrl;
  };
  
  

  const ImagePopup = ({ imageUrl, onClose }) => (
    <div className="image-popup-backdrop" onClick={onClose}>
      <div className="image-popup-content" onClick={e => e.stopPropagation()}>
        <img 
          src={imageUrl} 
          alt="Full size" 
          onError={(e) => {
            console.error(`Failed to load full image: ${imageUrl}`);
            onClose();
          }}
        />
        <button className="popup-close-button" onClick={onClose}>×</button>
      </div>
    </div>
  );
  const handleRemoveImage = (studentId, index) => {
    setStudentMedia(prev => ({
      ...prev,
      [studentId]: {
        ...prev[studentId],
        images: prev[studentId].images.filter((_, i) => i !== index)
      }
    }));
  };

  const handleVideoUrlChange = (studentId, url) => {
    setStudentMedia(prev => ({
      ...prev,
      [studentId]: {
        ...prev[studentId],
        videoUrl: url
      }
    }));
  };

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  return (
    <div
      className="attendance-dialog-backdrop"
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
    >
      <div className="attendance-dialog">
        <div className="attendance-dialog-header">
          <div className="header-content">
            <h2>Attendance for {selectedClass?.subject_name}</h2>
            <div className="class-details">
              <div className="class-date">
                <FontAwesomeIcon icon={faCalendar} className="icon" />
                {moment(selectedClass?.date).format("MMM DD, YYYY")}
              </div>
              <div className="class-time">
                <FontAwesomeIcon icon={faClock} className="icon" />
                {formatTime(selectedClass?.schedule_time)} -{" "}
                {calculateClassEndTime(
                  selectedClass?.schedule_time,
                  selectedClass?.schedule_hour
                )}
              </div>
            </div>
          </div>
          <div className="header-actions">
            <button onClick={downloadAttendanceCSV}>Download Report</button>
            <button className="close-button" onClick={onClose}>×</button>
          </div>
        </div>
        <div className="attendance-dialog-content">
          <table className="attendance-table">
            <thead>
              <tr>
                <th>{t("studentName")}</th>
                <th>{t("status")}</th>
                <th>{t("comments")}</th>
                <th>{t("media")}</th>
                <th>{t("video")}</th>
                <th>{t("time")}</th>
              </tr>
            </thead>
            <tbody>
              {registeredStudents.map((student) => {
                const savedRecord = savedAttendances[student.student_id];
                const currentAttendance = attendance[student.student_id] || {};
                const studentMediaData = studentMedia[student.student_id] || {};
  
                if (savedRecord) {
                  return (
                    <tr key={student.student_id}>
                      <td>{student.student_name} ({student.nickname})</td>
                      <td>
                        <div className={`attendance-status-saved status-${savedRecord.status.toLowerCase()}`}>
                          <FontAwesomeIcon icon={faLock} className="icon-lock" />
                          {savedRecord.status}
                        </div>
                      </td>
                      <td>{savedRecord.comment}</td>
  
                      {/* Image Column */}
<td>
  <div className="media-preview">
    {savedRecord.thumbnails?.map((thumbPath, index) => {
      const thumbnailUrl = getFullImageUrl(thumbPath);
      const fullImagePath = savedRecord.images ? savedRecord.images[index] : null;
      const fullImageUrl = fullImagePath ? getFullImageUrl(fullImagePath) : null;

      return (
        <div key={index} className="thumbnail-container">
          <img 
            src={thumbnailUrl}
            alt={`Student activity ${index + 1}`}
            className="uploaded-image-thumbnail"
            onClick={() => fullImageUrl ? setSelectedImage(fullImageUrl) : console.error('Full image URL not found')}
            onError={(e) => {
              console.error('Failed to load image:', { thumbPath, fullImageUrl, thumbnailUrl });
              e.target.style.display = 'none';
            }}
          />
        </div>
      );
    })}
  </div>
</td>

{/* Video Column */}
<td>
  {console.log('Video URL from savedRecord:', savedRecord.videoUrl)}
  {savedRecord.videoUrl ? (
    <div className="video-url">
      <FontAwesomeIcon icon={faVideo} />
      <a href={savedRecord.videoUrl} target="_blank" rel="noopener noreferrer">
        View Video
      </a>
    </div>
  ) : (
    <span>{t('noVideoAvailable')}</span>
  )}
</td>

                      <td>
                        <div className="attendance-timestamp">
                          <FontAwesomeIcon icon={faClock} className="icon-clock" />
                          {moment(savedRecord.timestamp).format("MMM DD, YYYY HH:mm")}
                        </div>
                      </td>
                    </tr>
                  );
                }
  
                return (
                  <tr key={student.student_id}>
                    <td>{student.student_name} ({student.nickname})</td>
                    <td>
                      <select
                        value={currentAttendance.status || ""}
                        onChange={(e) => handleAttendanceChange(
                          student.student_id,
                          "status",
                          e.target.value
                        )}
                        className="attendance-select"
                      >
                        <option value="">{t("selectStatus")}</option>
                        <option value="Present">{t("present")}</option>
                        <option value="Absent">{t("absent")}</option>
                        <option value="Late">{t("late")}</option>
                      </select>
                    </td>
                    <td>
                      <input
                        type="text"
                        value={currentAttendance.comment || ""}
                        onChange={(e) => handleAttendanceChange(
                          student.student_id,
                          "comment",
                          e.target.value
                        )}
                        placeholder="Add Comment"
                        className="attendance-input"
                      />
                    </td>
  
                    {/* Upload Section for New Entries */}
                    <td className="media-upload-cell">
                      {/* Image Upload Section */}
                      <div className="image-upload-section">
                        <label className="upload-button">
                          <FontAwesomeIcon icon={faImage} />
                          <span>Upload Images ({(studentMediaData.images || []).length}/{MAX_IMAGES})</span>
                          <input
                            type="file"
                            multiple
                            accept={ACCEPTED_IMAGE_TYPES}
                            onChange={(e) => handleImageUpload(student.student_id, e.target.files)}
                            style={{ display: 'none' }}
                            disabled={(studentMediaData.images || []).length >= MAX_IMAGES}
                          />
                        </label>
                        {/* Image Previews */}
                        <div className="image-preview-container">
                          {studentMediaData.images?.map((img, index) => (
                            <div key={index} className="image-preview">
                              <img src={img.preview} alt={`Preview ${index + 1}`} />
                              <button
                                className="remove-image"
                                onClick={() => handleRemoveImage(student.student_id, index)}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </td>
  
                    <td>
                      <input
                        type="url"
                        placeholder="Add video URL"
                        value={studentMediaData.videoUrl || ''}
                        onChange={(e) => handleVideoUrlChange(student.student_id, e.target.value)}
                        className="video-url-input"
                      />
                    </td>
  
                    <td>
                      <button
                        onClick={() => handleSaveAttendance(student.student_id)}
                        disabled={savingInProgress || !currentAttendance.status}
                        className="attendance-save-button"
                      >
                        <FontAwesomeIcon icon={faSave} className="save-icon" />
                        Save Attendance
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {selectedImage && (
        <ImagePopup 
          image={selectedImage} 
          onClose={() => setSelectedImage(null)} 
        />
      )}
    </div>
  );
  
};

export default AttendanceModal;