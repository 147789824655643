import React, { useEffect, useState } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { 
  Layout,
  Card,
  Typography,
  Avatar,
  Space,
  Spin,
  Alert,
  Tag,
  Divider,
  Row,
  Col
} from 'antd';
import {
  BookOutlined,
  TrophyOutlined,
  ScheduleOutlined
} from '@ant-design/icons';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { refreshToken, isTokenExpiredError } from '../../Utils/authUtils';

const { Content } = Layout;
const { Title, Text, Paragraph } = Typography;
const { Meta } = Card;

function Teacher() {
  const { t } = useTranslation();
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/teachers`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTeachers(response.data);
        setLoading(false);
      } catch (error) {
        if (isTokenExpiredError(error)) {
          refreshToken()
            .then(() => {
              fetchTeachers();
            })
            .catch(err => {
              console.error('Token refresh failed:', err);
              setError(t('sessionExpired'));
            });
        } else {
          console.error('Error fetching teachers:', error);
          setError(t('errorFetchingTeachers'));
        }
        setLoading(false);
      }
    };

    fetchTeachers();
  }, [API_URL, t]);

  const EXCLUDED_IDS = [16, 51, 52, 54];

  const renderEducation = (degree, school) => {
    if (!degree) return null;
    return (
      <Space direction="vertical" size={0}>
        <Text strong>{degree}</Text>
        <Text type="secondary">{school}</Text>
      </Space>
    );
  };

  const renderTeacherCard = (teacher) => {
    const specializations = teacher.specialize_subjects?.split(',') || [];

    return (
      <Card
        hoverable
        style={{ height: '100%' }}
        cover={
          teacher.photourl && (
            <div style={{ 
              height: 300, 
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#f5f5f5'
            }}>
              <img
                alt={`${teacher.firstname} ${teacher.lastname}`}
                src={teacher.photourl}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
            </div>
          )
        }
      >
        <Meta
          title={
            <Space direction="vertical" size={2} style={{ width: '100%' }}>
              <Title level={4} style={{ margin: 0 }}>
                {teacher.firstname} {teacher.lastname}
              </Title>
              {teacher.nickname && (
                <Text type="secondary">({teacher.nickname})</Text>
              )}
            </Space>
          }
          description={
            <Space direction="vertical" size={16} style={{ width: '100%', marginTop: 16 }}>
              {specializations.length > 0 && (
                <Space size={[0, 8]} wrap>
                  <BookOutlined style={{ marginRight: 8 }} />
                  {specializations.map((subject, index) => (
                    <Tag key={index} color="blue">
                      {subject.trim()}
                    </Tag>
                  ))}
                </Space>
              )}

              {(teacher.bachelor_degree || teacher.master_degree || teacher.doctoral_degree) && (
                <Space direction="vertical" size={8} style={{ width: '100%' }}>
                  <TrophyOutlined style={{ marginRight: 8 }} />
                  {renderEducation(teacher.doctoral_degree, teacher.doctoral_school)}
                  {renderEducation(teacher.master_degree, teacher.master_school)}
                  {renderEducation(teacher.bachelor_degree, teacher.bachelor_school)}
                </Space>
              )}

              {teacher.schedule && (
                <Paragraph style={{ marginBottom: 0 }}>
                  <ScheduleOutlined style={{ marginRight: 8 }} />
                  {teacher.schedule}
                </Paragraph>
              )}
            </Space>
          }
        />
      </Card>
    );
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ padding: 24 }}>
        <Alert message={error} type="error" showIcon />
      </div>
    );
  }

  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Header />
        <Content style={{ marginTop: '50px', marginBottom: '70px', padding: '24px', minHeight: '280px', background: '#f5f5f5' }}>
          <div style={{ maxWidth: 1200, margin: '0 auto' }}>
            
            <Row gutter={[24, 24]}>
              {teachers
                .filter((teacher) => 
                  teacher.status === 'active' && 
                  !EXCLUDED_IDS.includes(teacher.userid) &&
                  !EXCLUDED_IDS.includes(teacher.teacherid)
                )
                .sort((a, b) => a.userid - b.userid)
                .map((teacher) => (
                  <Col 
                    key={teacher.userid}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}
                    xl={8}
                  >
                    {renderTeacherCard(teacher)}
                  </Col>
                ))}
            </Row>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default withRoleAccess(Teacher, ['admin', 'superadmin', 'teacher', 'student']);