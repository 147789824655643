import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Modal from '../../components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faPrint } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import SignatureCanvas from 'react-signature-canvas';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { sarabunFont } from '../../sarabunFont';
import CreatableSelect from 'react-select/creatable'; 
import './Registrations.css';

// Function to load the Sarabun font into jsPDF
const loadSarabunFont = (doc) => {
  doc.addFileToVFS('Sarabun.ttf', sarabunFont.src.split('base64,')[1]);
  doc.addFont('Sarabun.ttf', 'Sarabun', 'normal');
  doc.setFont('Sarabun');
};

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return 'N/A';
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return new Intl.DateTimeFormat('en-GB', options).format(date).toUpperCase();
};

const formatTime = (timeString) => {
  const [hours, minutes] = timeString.split(':');
  return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
};

const formatNumber = (number) => {
  const parsedNumber = parseFloat(number);
  if (isNaN(parsedNumber)) {
      return '0.00';
  }
  return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
  }).format(parsedNumber);
};



const Registrations = () => {
  const { t } = useTranslation();
  const [students, setStudents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState('');
  const [studentDetails, setStudentDetails] = useState({});
  const [selectedClass, setSelectedClass] = useState('');
  const [schedule, setSchedule] = useState([]);
  const [promotion, setPromotion] = useState('');
  const [price, setPrice] = useState(0);
  const [inventoryPrice, setInventoryPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [memo, setMemo] = useState('');
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false);
  const [vat, setVat] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const sigCanvas = useRef(null);
  const [inventoryItems, setInventoryItems] = useState([]);
  const [selectedInventoryItems, setSelectedInventoryItems] = useState([]);
  const [baseClassPrice, setBaseClassPrice] = useState(0);
  const [priceBeforeVat, setPriceBeforeVat] = useState(0);
  const [classPackages, setClassPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);


  const API_URL = process.env.REACT_APP_API_URL;

  const calculateEndTime = (startTime, scheduleHour) => {
    // Split the scheduleHour into hours and minutes (e.g., '2h 30m' => 2 hours, 30 minutes)
    const hourParts = scheduleHour.split(' '); 
    const hours = parseInt(hourParts[0], 10); // Extract hours from '2h'
    const minutes = hourParts.length > 1 ? parseInt(hourParts[1], 10) : 0; // Extract minutes if present
  
    // Split startTime (format 'HH:mm') into hours and minutes
    const [startHour, startMinute] = startTime.split(':').map(Number);
  
    // Use moment to add the hours and minutes to the start time
    const startMoment = moment().hours(startHour).minutes(startMinute);
  
    // Add the extracted hours and minutes to calculate the end time
    const endMoment = startMoment.add(hours, 'hours').add(minutes, 'minutes');
  
    // Return the formatted end time as 'HH:mm'
    return endMoment.format('HH:mm');
  };

  const formatMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')} ${t('hrs')}`;
  };
  

  const fetchStudents = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/students`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      const studentsData = response.data.students || [];
      
      const activeStudents = studentsData.filter(student => student.status === 'active');
  
      setStudents(activeStudents);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  }, [API_URL]);
  

  const fetchClasses = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/class-groups/classgroup-price`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      console.log('API Response:', response.data); // Debug log
  
      const classesData = Array.isArray(response.data) ? response.data : [];
      const filteredClasses = classesData.filter((classGroup) => {
        const endDate = new Date(classGroup.end_date);
        const today = new Date();
        return endDate >= today;
      });
  
      console.log('Filtered classes with packages:', filteredClasses); // Debug log
      setClasses(filteredClasses);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  }, [API_URL]);

  const fetchInventoryItems = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/inventory`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      const inventoryData = response.data || [];
  
      // Filter inventory items with status 'active'
      const activeInventoryItems = inventoryData.filter(item => item.status === 'active');
  
      setInventoryItems(activeInventoryItems);
    } catch (error) {
      console.error('Error fetching inventory items:', error);
    }
  }, [API_URL]);
  
useEffect(() => {
    fetchStudents();
    fetchClasses();
    fetchInventoryItems(); // Fetch inventory items when component mounts
}, [fetchStudents, fetchClasses, fetchInventoryItems]);

const studentOptions = students.map(student => ({
  value: student.userid,
  label: `${student.nickname} ${student.firstname} ${student.lastname}`
}));

const classOptions = classes.map(cls => ({
  value: cls.groupid,
  label: `${cls.class_code} ${t('start_date')} ${formatDate(cls.start_date)}`
}));


const packageOptions = classPackages.map(pkg => ({
  value: pkg.id,  // Use the actual id from package_pricing
  id: pkg.id,     // Also include the id separately for clarity
  label: `${pkg.number_of_classes} Classes: ${formatNumber(pkg.price_per_package)} THB (${pkg.validity_months} months)`,
  price: parseFloat(pkg.price_per_package),
  price_per_package: parseFloat(pkg.price_per_package),
  classes: parseInt(pkg.number_of_classes),
  validity_months: parseInt(pkg.validity_months),
  classgroup_id: pkg.classgroup_id
}));


const inventoryOptions = inventoryItems.map(item => ({
  value: item.inventory_id,
  label: `${item.item_name} (${formatNumber(item.price_per_unit)})`,
  price_per_unit: parseFloat(item.price_per_unit), // Ensure this is a number
  vat: item.vat // Add VAT if applicable
}));


  const handleStudentSelect = (selectedOption) => {
    setSelectedStudent(selectedOption ? selectedOption.value : '');
    const selectedStudent = students.find(student => student.userid === selectedOption.value);
    setStudentDetails(selectedStudent || {});
  };

  const handleClassSelect = (selectedOption) => {
    setSelectedClass(selectedOption ? selectedOption.value : '');
    const selectedClass = classes.find(cls => cls.groupid === selectedOption.value);
    
    if (selectedClass) {
      console.log('Selected class packages:', selectedClass.package_pricing); // Debug log
      setSchedule(selectedClass.classes || []);
      setClassPackages(selectedClass.package_pricing || []); // Make sure to set packages
      const classPrice = parseFloat(selectedClass.price) || 0;
      setBaseClassPrice(classPrice);
      setPrice(classPrice);
    } else {
      setSchedule([]);
      setClassPackages([]);
      setBaseClassPrice(0);
      setPrice(0);
    }
  };
  

  const handlePackageSelect = (selectedOption) => {
    console.log('Selected package details:', {
      raw: selectedOption,
      id: selectedOption?.id,
      price: selectedOption?.price,
      classes: selectedOption?.classes,
      validity_months: selectedOption?.validity_months
    });
  
    setSelectedPackage(selectedOption ? {
      id: selectedOption.id,                     // Use the correct id
      price: selectedOption.price,
      price_per_package: selectedOption.price_per_package,
      number_of_classes: selectedOption.classes,
      validity_months: selectedOption.validity_months,
      classgroup_id: selectedOption.classgroup_id
    } : null);
  
    if (selectedOption) {
      const packagePrice = parseFloat(selectedOption.price);
      setPrice(packagePrice);
      
      // Calculate new price before VAT including all components
      const newPriceBeforeVat = packagePrice + inventoryPrice - (parseFloat(promotion) || 0);
      setPriceBeforeVat(newPriceBeforeVat);
      calculateTotalPrice(newPriceBeforeVat, promotion, vat);
    }
  };
  
  
  const handleInventorySelect = (selectedOptions) => {
    setSelectedInventoryItems(selectedOptions);
  
    // Calculate inventory cost
    const inventoryCost = selectedOptions.reduce((total, item) => {
      return total + (parseFloat(item.price_per_unit) || 0);
    }, 0);
  
    setInventoryPrice(inventoryCost);
  
    // Use current package price instead of baseClassPrice
    const currentPrice = selectedPackage ? parseFloat(selectedPackage.price) : price;
    
    // Calculate new price before VAT including all components
    const newPriceBeforeVat = currentPrice + inventoryCost - (parseFloat(promotion) || 0);
    setPriceBeforeVat(newPriceBeforeVat);
    calculateTotalPrice(newPriceBeforeVat, promotion, vat);
  };
  
  const handlePromotionChange = (e) => {
    const promoValue = e.target.value;
    setPromotion(promoValue);
  
    const parsedPromotion = parseFloat(promoValue) || 0;
    // Use current package price instead of baseClassPrice
    const currentPrice = selectedPackage ? parseFloat(selectedPackage.price) : price;
    
    // Calculate new price before VAT including all components
    const newPriceBeforeVat = currentPrice + inventoryPrice - parsedPromotion;
    setPriceBeforeVat(newPriceBeforeVat);
    calculateTotalPrice(newPriceBeforeVat, promoValue, vat);
  };
  
  const handleVatChange = (e) => {
    const isChecked = e.target.checked;
    setVat(isChecked);
    calculateTotalPrice(priceBeforeVat, promotion, isChecked);
  };
  
  const calculateTotalPrice = (priceBeforeVat, promotion, vat) => {
    let finalPrice = priceBeforeVat || 0;
  
    // Apply VAT if checked
    if (vat) {
      finalPrice = finalPrice * 1.07;
    }
  
    setTotalPrice(finalPrice);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const clearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
  };

// Frontend: Update the handleConfirmPayment function
const handleConfirmPayment = async () => {
  setIsPaymentConfirmed(true);

  // Validate promotion
  const validPromotion = promotion ? parseFloat(promotion) : 0;
  if (isNaN(validPromotion)) {
      alert("Invalid promotion value");
      return;
  }

  // Get current price and validate
  const validTotalPrice = parseFloat(totalPrice);
  if (isNaN(validTotalPrice) || validTotalPrice <= 0) {
      alert("Invalid total price value");
      return;
  }

  const userData = JSON.parse(localStorage.getItem('userData'));
  const userId = userData ? userData.userId : null;

  const signature = sigCanvas.current.isEmpty() ? null : sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');

  // Prepare package information - ensure ID is handled as an integer
  const packageInfo = selectedPackage ? {
    id: selectedPackage.id,                    // Use the correct id from the package
    price_per_package: selectedPackage.price_per_package,
    number_of_classes: selectedPackage.number_of_classes,
    validity_months: selectedPackage.validity_months,
    classgroup_id: selectedPackage.classgroup_id
  } : null;

  // Calculate total classes based on package or default to 1
  const finalTotalClasses = packageInfo ? packageInfo.number_of_classes : 1;

  // Calculate final price components
  const finalBasePrice = selectedPackage ? parseFloat(selectedPackage.price) : parseFloat(price);
  const priceWithInventory = finalBasePrice + parseFloat(inventoryPrice || 0);
  const priceWithDiscount = priceWithInventory - validPromotion;
  const finalFinalPrice = vat ? priceWithDiscount * 1.07 : priceWithDiscount;

  // Preparing registration data with explicit package and price information
  const registrationData = {
      studentId: selectedStudent,
      classId: selectedClass,
      selectedPackage: packageInfo ? {
          ...packageInfo,
          id: parseInt(packageInfo.id) // Ensure ID is an integer
      } : null,
      totalClasses: finalTotalClasses,
      promotion: validPromotion,
      finalPrice: finalFinalPrice,
      paymentMethod: paymentMethod,
      paymentConfirmed: !!signature,
      createdBy: userId,
      price: finalBasePrice,
      discount: validPromotion,
      signature: signature,
      memo: memo,
      inventoryItems: selectedInventoryItems.map(item => ({
          ...item,
          value: parseInt(item.value) || null // Ensure inventory IDs are integers
      })),
      inventoryPrice: parseFloat(inventoryPrice) || 0,
      vat: vat
  };

  console.log('Sending registration data:', registrationData); // Debug log

  try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_URL}/registrations`, registrationData, {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });

      console.log('Registration response:', response.data);
      setConfirmationModalOpen(true);

      // Reset form
      setSelectedStudent('');
      setStudentDetails({});
      setSelectedClass('');
      setSchedule([]);
      setPromotion('');
      setPrice(0);
      setTotalPrice(0);
      setPaymentMethod('');
      setMemo('');
      setIsPaymentConfirmed(false);
      setVat(false);
      setInventoryPrice(0);
      setSelectedInventoryItems([]);
      setSelectedPackage(null);
      clearSignature();
      closeModal();
  } catch (error) {
      console.error('Error saving registration:', error.response?.data || error.message);
      alert('Error saving registration. Please check the console for details.');
  }
};



  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const generateSchedulePDF = (schedule) => {
    const doc = new jsPDF();
    loadSarabunFont(doc);
    doc.setFont('Sarabun', 'normal');
  
    const margin = 20;
    const startY = margin + 60; // Position after the header
  
    // Sort the schedule by date and time (earlier to later)
    const sortedSchedule = schedule.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
  
      if (dateA.getTime() === dateB.getTime()) {
        // If dates are the same, sort by schedule_time
        return a.schedule_time.localeCompare(b.schedule_time);
      }
      return dateA - dateB;
    });
  
    // Load and add the header image
    const headerImage = new Image();
    headerImage.src = '/images/Theplanner.png'; // Adjust the path as needed
  
    headerImage.onload = () => {
      // Add header image (40x40 px)
      doc.addImage(headerImage, 'PNG', margin, margin, 35, 30);
  
      // Add header text
      doc.setFontSize(10);
      doc.setFont('helvetica', 'bold');
      doc.text('THE PLANNER EDUCATION', margin + 50, margin + 10);
  
      doc.setFont('helvetica', 'normal');
      doc.text('The Mercury Ville @Chidlom 3rd floor', margin + 50, margin + 15);
      doc.text('Lumpini Pathumwan Bangkok', margin + 50, margin + 20);
      doc.text('www.theplannereducation.com', margin + 50, margin + 25);
      doc.text('Tel. 02-253-2533 or 095-726-2666 Line: @theplanner', margin + 50, margin + 30);
  
      // Prepare class details data
      const classCode = sortedSchedule[0]?.class_code || 'N/A'; // Get class_code from sorted schedule
      const totalHours = sortedSchedule?.[0]?.total_hours || 0;
      const subjects = [...new Set(sortedSchedule.map(slot => slot.subject_name))]; // Get unique subjects
      const subjectsText = subjects.join(', ');
  
   // Add class details below the header (centered)
      doc.setFontSize(12);
      const pageWidth = doc.internal.pageSize.getWidth(); // Get the page width

      doc.text(`Course: ${classCode}`, pageWidth / 2, startY - 15, { align: 'center' });
      doc.text(`Total Hours: ${formatMinutesToHours(totalHours)}`, pageWidth / 2, startY - 10, { align: 'center' });
      doc.text(`Subject(s): ${subjectsText}`, pageWidth / 2, startY - 5, { align: 'center' });

  
      // Add the main title
      doc.setFontSize(18);
      doc.setFont('Sarabun', 'normal');
      doc.text('', 105, startY, { align: 'center' });
  
      // Create the table with class schedule details
      doc.autoTable({
        startY: startY + 10,
        head: [['Date', 'Day', 'Time', 'End Time', 'Hours', 'Subject Name']],
        body: sortedSchedule.map(slot => [
          formatDate(slot.date),
          slot.schedule_day,
          formatTime(slot.schedule_time),
          calculateEndTime(slot.schedule_time, slot.schedule_hour),
          slot.schedule_hour,
          slot.subject_name,
        ]),
        styles: { font: 'Sarabun', fontStyle: 'normal' }
      });
  
      // Save the PDF with the classCode as the file name
      doc.save(`${classCode}.pdf`);
    };
  };
  


const generateQuotationPDF = () => {
  const doc = new jsPDF();
  loadSarabunFont(doc);
  doc.setFont('Sarabun', 'normal');

  // Load and add the header image
  const headerImage = new Image();
  headerImage.src = '/images/plann_logo_white.png'; // Adjust the path as needed

  headerImage.onload = () => {
      // Add header image (40x40 px)
      const margin = 10;
      doc.addImage(headerImage, 'PNG', margin, margin, 35, 30);

      // Add header text
      doc.setFontSize(10);
      doc.setFont('helvetica', 'bold');
      doc.text('PLANN TECH', margin + 50, margin + 10);

      doc.setFont('helvetica', 'normal');
      doc.text('PLANN TECH', margin + 50, margin + 15);
      doc.text('Bangkok', margin + 50, margin + 20);
      doc.text('www.plann.tech]', margin + 50, margin + 25);
      doc.text('098-765-4321', margin + 50, margin + 30);

      // Add date and expiration information
      const today = moment().format('DD MMM YYYY');
      const expirationDate = moment().add(2, 'days').format('DD MMM YYYY');
      const startY = 50; // Start below the header
      doc.text(`${t('registration.date_of_quotation')}: ${today}`, margin, startY);
      doc.text(`${t('registration.quotation_expiration')}: ${expirationDate}`, margin, startY + 5);

      // Student and class details
      doc.text(`${t('registration.student_name')}: ${studentDetails.firstname} ${studentDetails.lastname}`, margin, startY + 15);
      doc.text(`${t('registration.address')}: ${studentDetails.address || 'N/A'}`, margin, startY + 20);
      doc.text(`${t('registration.phone')}: ${studentDetails.phone || 'N/A'}`, margin, startY + 25);
      
      // Main table with course and inventory details
      const bodyContent = [
          [
              '1',
              `${classes.find(cls => cls.groupid === selectedClass)?.class_code || 'N/A'}`,
              '1',
              'Course',
              formatNumber(baseClassPrice),
              formatNumber(baseClassPrice)
          ],
          ...selectedInventoryItems.map((item, index) => [
              (index + 2).toString(),
              item.label,
              '1',
              'Book',
              formatNumber(item.price_per_unit),
              formatNumber(item.price_per_unit)
          ])
      ];

      // Add summary at the end
      const totalBeforeDiscount = baseClassPrice + selectedInventoryItems.reduce((sum, item) => sum + item.price_per_unit, 0);
      const totalAfterDiscount = totalBeforeDiscount - (parseFloat(promotion) || 0);
      const vatAmount = vat ? totalAfterDiscount * 0.07 : 0; // Calculate VAT 7% only if VAT is checked
      const grandTotal = totalAfterDiscount + vatAmount; // Grand total includes VAT if applicable

      bodyContent.push(
          ['', '', '', '', 'Total', formatNumber(totalBeforeDiscount)],
          ['', '', '', '', 'Discount', formatNumber(promotion)],
          ['', '', '', '', 'Total after discount', formatNumber(totalAfterDiscount)],
          ['', '', '', '', 'VAT 7%', formatNumber(vatAmount)], // Show VAT amount calculated
          ['', '', '', '', 'Grand Total', formatNumber(grandTotal)] // Show grand total including VAT
      );

      doc.autoTable({
          startY: startY + 40,
          head: [['#', 'Description', 'Quantity', 'Unit', 'Unit Price', 'Total']],
          body: bodyContent,
          styles: { font: 'Sarabun', fontStyle: 'normal' },
          columnStyles: {
              0: { cellWidth: 10 },
              1: { cellWidth: 90 },
              2: { cellWidth: 20, halign: 'center' },
              3: { cellWidth: 20, halign: 'center' },
              4: { cellWidth: 25, halign: 'right' },
              5: { cellWidth: 25, halign: 'right' }
          }
      });

      // Add QR code image for payment at the bottom
      const qrCodeImage = 'https://pbs.twimg.com/media/DY8Qm7_U0AAzaFm.jpg';
      doc.addImage(qrCodeImage, 'JPEG', margin, doc.lastAutoTable.finalY + 10, 50, 0); // Set height to 0 to maintain aspect ratio

      // Save PDF
      doc.save('quotation.pdf');
  };
};


return (
  <div className="registration-container">
    <Sidebar />
    <Header />
    <h1>{t('registration.register_for_course')}</h1>
    <form className="registration-form">
      <div className="form-left">
        <div className="re-form-group">
          <label htmlFor="student">{t('registration.select_student')}</label>
          <Select
            options={studentOptions}
            onChange={handleStudentSelect}
            placeholder={t('registration.search_and_select_student')}
          />
        </div>
        {selectedStudent && (
          <div className="re-form-address">
            <p><strong>{t('registration.address')}:</strong> {studentDetails.address || 'N/A'}</p>
            <p><strong>{t('registration.phone')}:</strong> {studentDetails.phone || 'N/A'}</p>
          </div>
        )}
        <div className="re-form-group">
          <label htmlFor="class">{t('registration.select_class')}</label>
          <Select
            options={classOptions}
            onChange={handleClassSelect}
            placeholder={t('registration.select_class')}
          />
        </div>
        {classPackages && classPackages.length > 0 && (
  <div className="re-form-group">
    <label htmlFor="package">{t('registration.select_package')}</label>
    <Select
      options={packageOptions}
      onChange={handlePackageSelect}
      value={selectedPackage}
      placeholder={t('registration.select_package')}
      isClearable
      formatOptionLabel={option => (
        <div>
          <div>{option.label}</div>
          <small style={{ color: '#666' }}>
            {option.classes} classes, valid for {option.validity} months
          </small>
        </div>
      )}
    />
  </div>
)} 
        <div className="re-form-group">
          <label>{t('registration.select_inventory_items')}</label>
          <CreatableSelect
            options={inventoryOptions}
            onChange={handleInventorySelect}
            value={selectedInventoryItems}
            isMulti
            placeholder={t('registration.select_inventory')}
          />
        </div>

        {selectedClass && (
          <div className="re-form-group">
            <label>{t('registration.class_schedule')}</label>
            <div className="schedule-list">
            {schedule.map((slot, index) => (
              <div key={index} className="schedule-item">
                <span>{formatDate(slot.date)} {slot.schedule_day}: </span>
                <span>{formatTime(slot.schedule_time)} ({slot.schedule_hour}) - </span>
                <span>{slot.subject_name}</span>
              </div>
            ))}
          </div>
          </div>
        )}
        <button type="button" className="submit-button" onClick={() => generateSchedulePDF(schedule)}>
          <FontAwesomeIcon icon={faPrint} /> {t('registration.print_schedule')} (English Only)
        </button>
      </div>
      <div className="form-right">
  <div className="re-form-group">
    <label>{t('registration.class_price')}</label>
    <p className="price-display">{formatNumber(price)}</p>
  </div>
  <div className="re-form-group">
    <label>{t('registration.inventory_price')}</label>
    <p className="price-display">{formatNumber(inventoryPrice)}</p>
  </div>
  <div className="re-form-group">
    <label>{t('registration.promotion_discount')}</label>
    <input
      type="text"
      id="promotion"
      value={promotion}
      onChange={handlePromotionChange}
      placeholder={t('registration.enter_promotion_value')}
    />
  </div>
  <div className="re-form-group">
    <label>{t('registration.price_before_vat')}</label>
    <p className="price-display">{formatNumber(priceBeforeVat)}</p>
  </div>
  <div className="custom-checkbox">
    <input type="checkbox" id="vat" checked={vat} onChange={handleVatChange} />
    <label htmlFor="vat" className="checkbox-label">{t('registration.vat')}</label>
    {vat && <span className="vat-amount">{formatNumber(priceBeforeVat * 0.07)}</span>}
  </div>
  <div className="re-form-group">
    <label>{t('registration.total_price')}</label>
    <p className="price-display">{formatNumber(totalPrice)}</p>
  </div>


        <button type="button" className="quotation-button" onClick={generateQuotationPDF}>
          <FontAwesomeIcon icon={faFileInvoice} /> {t('registration.quotation')} (English Only)
        </button>
        <div className="re-form-group">
          <label htmlFor="paymentMethod">{t('registration.select_payment_method')}</label>
          <select id="paymentMethod" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
            <option value="">{t('registration.select_payment_method')}</option>
            <option value="cash">{t('registration.cash')}</option>
            <option value="transfer">{t('registration.transfer')}</option>
            <option value="qr">{t('registration.qr_code')}</option>
            <option value="credit">{t('registration.credit_card')}</option>
          </select>
        </div>

        <div className="re-form-group">
          <label htmlFor="memo">{t('registration.memo')}</label>
          <textarea
            id="memo"
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
            placeholder={t('registration.enter_additional_notes')}
          />
        </div>
        <div className="re-form-group">
          <label className="checkbox-label">{t('registration.confirm_payment')}</label>
          <SignatureCanvas
            ref={sigCanvas}
            penColor="black"
            canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
          />
        </div>
        <div className="re-form-group">
          <button type="button" onClick={clearSignature} className="singature-button">{t('registration.clear_signature')}</button>
        </div>

        <button type="button" className="submit-button" onClick={openModal}>
          <FontAwesomeIcon icon={faFileInvoice} /> {t('registration.confirm_registration')}
        </button>
      </div>
    </form>
    {isPaymentConfirmed && <p>{t('registration.payment_confirmed')}</p>}
    <Modal isOpen={isModalOpen} onClose={closeModal}>
      <h2>{t('registration.confirm_registration_details')}</h2>
      <div>
        <p><strong>{t('registration.student')}:</strong> {studentDetails.firstname} {studentDetails.lastname}</p>
        <p><strong>{t('registration.address')}:</strong> {studentDetails.address || 'N/A'}</p>
        <p><strong>{t('registration.phone')}:</strong> {studentDetails.phone || 'N/A'}</p>
        <p><strong>{t('registration.class')}:</strong> {classes.find(cls => cls.groupid === selectedClass) ? `${classes.find(cls => cls.groupid === selectedClass).class_code} - ${classes.find(cls => cls.groupid === selectedClass).subject_name}` : selectedClass}</p>
        <p><strong>{t('registration.price')}:</strong> {formatNumber(price)}</p>
        <p><strong>{t('registration.promotion')}:</strong> {promotion}</p>
        <p><strong>{t('registration.vat')}:</strong> {vat ? formatNumber(price * 0.07) : 'N/A'}</p>
        <p><strong>{t('registration.total_price')}:</strong> {formatNumber(totalPrice)}</p>
        <p><strong>{t('registration.payment_method')}:</strong> {paymentMethod}</p>
        <p><strong>{t('registration.memo')}:</strong> {memo}</p>
        {sigCanvas.current && !sigCanvas.current.isEmpty() && <img src={sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')} alt="signature" style={{ width: '100%', maxHeight: '200px' }} />}
      </div>
      <button type="button" onClick={handleConfirmPayment}>{t('registration.confirm')}</button>
    </Modal>
    <Modal isOpen={confirmationModalOpen} onClose={closeConfirmationModal}>
      <h2>{t('registration.successful_registration')}</h2>
    </Modal>
  </div>
);
}

export default withRoleAccess(Registrations, ['superadmin', 'admin']);